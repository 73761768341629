@import '../../../assets/css/variables';

nav {
    .navMenuBtn {
        height: 20px;

        .menuTrigger {
            width: 18px;
            height: 2px;

            &::after,
            &::before {
                content: "";
                position: absolute;
                width: 18px;
                height: 2px;
                background-color: $black;
                right: 50%;
                transform: translateX(50%);
                transition: all .3s ease-in-out;
            }

            &::after {
                bottom: -5px;
            }

            &::before {
                top: -5px;
            }

            &.active {
                @include below-xl() {
                    visibility: hidden;
                    width: 15px;
                }

                &::after {
                    width: 10px;
                    transform: rotate(-45deg);

                    @include above-xl() {
                        right: -2px;
                        bottom: -3px;

                    }

                    @include below-xl() {
                        visibility: visible;
                        width: 15px;
                        bottom: 0;
                        right: 0;
                    }
                }

                &::before {
                    width: 10px;
                    transform: rotate(45deg);

                    @include above-xl() {
                        right: -2px;
                        top: -3px;
                    }

                    @include below-xl() {
                        visibility: visible;
                        width: 15px;
                        top: 0;
                        right: 0;

                    }
                }

            }
        }

    }

    .mh-60 {
        min-height: 3.75rem;
    }

    .notificationDropdown {
        .dropdown-toggle {
            .customBadge {
                line-height: 0.601;
                margin-left: -1.1rem;
                margin-top: 6px;
            }

            &::after {
                display: none;
            }
        }
    }

    .profileDropdown {
        .dropdown-toggle {
            &::after {
                display: none;
            }
        }
    }
}