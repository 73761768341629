.user-select{
    .ant-select{
        padding:0px;
        .ant-select-selector{
            border:2px solid transparent!important;
            border-color: none!important;
            box-shadow: none!important;
        }
    }   
    .ant-select:hover{
        .ant-select-selector{
            border-color:none;
        } 
    }
  
    .ant-select-focused , .ant-select-open{
        .ant-select-selector{
            border-color : #181A20!important;
            box-shadow: none!important;
        }
        .ant-select-selector:hover{
            border-color : #181A20!important;
        } 
       
    } 
    .ant-select-selector:hover{
        border-color : transparent!important;
    } 
    
}
.ant-select-item-option-selected{
    background-color: rgba(244, 139, 25, 0.07)!important;
}
