@import "./assets/css/variables";

// @import "~bootstrap/scss/bootstrap";
:root {
  // Custom Toast CSS
  --toastify-color-dark: var(--bs-dark);
  --toastify-color-success: var(--bs-success);
  --toastify-color-error: var(--bs-danger);
  --toastify-icon-color-success: var(--bs-success);
  --toastify-icon-color-error: var(--bs-danger);
  --toastify-toast-min-height: 1.5rem;
  --toastify-font-family: 14px;
  --toastify-text-color-light: var(--bs-dark);
  --toastify-spinner-color: var(--bs-light);
  --toastify-color-progress-success: var(--bs-success);
  --toastify-color-progress-error: var(--bs-danger);
}

@font-face {
  font-family: "SF UI Display bold";
  src: url("assets/fonts/SFUIDisplay-Bold.eot");
  src: url("assets/fonts/SFUIDisplay-Bold.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/SFUIDisplay-Bold.woff2") format("woff2"),
    url("assets/fonts/SFUIDisplay-Bold.woff") format("woff"),
    url("assets/fonts/SFUIDisplay-Bold.ttf") format("truetype"),
    url("assets/fonts/SFUIDisplay-Bold.svg#SFUIDisplay-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "SF UI Display light";
  src: url("assets/fonts/SFUIDisplay-Light.eot");
  src: url("assets/fonts/SFUIDisplay-Light.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/SFUIDisplay-Light.woff2") format("woff2"),
    url("assets/fonts/SFUIDisplay-Light.woff") format("woff"),
    url("assets/fonts/SFUIDisplay-Light.ttf") format("truetype"),
    url("assets/fonts/SFUIDisplay-Light.svg#SFUIDisplay-Light") format("svg");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "SF UI Display black";
  src: url("assets/fonts/SFUIDisplay-Black.eot");
  src: url("assets/fonts/SFUIDisplay-Black.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/SFUIDisplay-Black.woff2") format("woff2"),
    url("assets/fonts/SFUIDisplay-Black.woff") format("woff"),
    url("assets/fonts/SFUIDisplay-Black.ttf") format("truetype"),
    url("assets/fonts/SFUIDisplay-Black.svg#SFUIDisplay-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF UI Display hevy";
  src: url("assets/fonts/SFUIDisplay-Heavy.eot");
  src: url("assets/fonts/SFUIDisplay-Heavy.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/SFUIDisplay-Heavy.woff2") format("woff2"),
    url("assets/fonts/SFUIDisplay-Heavy.woff") format("woff"),
    url("assets/fonts/SFUIDisplay-Heavy.ttf") format("truetype"),
    url("assets/fonts/SFUIDisplay-Heavy.svg#SFUIDisplay-Heavy") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF UI Display Medium";
  src: url("assets/fonts/SFUIDisplay-Medium.eot");
  src: url("assets/fonts/SFUIDisplay-Medium.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/SFUIDisplay-Medium.woff2") format("woff2"),
    url("assets/fonts/SFUIDisplay-Medium.woff") format("woff"),
    url("assets/fonts/SFUIDisplay-Medium.ttf") format("truetype"),
    url("assets/fonts/SFUIDisplay-Medium.svg#SFUIDisplay-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF UI Display Regular";
  src: url("assets/fonts/SFUIDisplay-Regular.eot");
  src: url("assets/fonts/SFUIDisplay-Regular.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/SFUIDisplay-Regular.woff2") format("woff2"),
    url("assets/fonts/SFUIDisplay-Regular.woff") format("woff"),
    url("assets/fonts/SFUIDisplay-Regular.ttf") format("truetype"),
    url("assets/fonts/SFUIDisplay-Regular.svg#SFUIDisplay-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF UI Display Semibold";
  src: url("assets/fonts/SFUIDisplay-Semibold.eot");
  src: url("assets/fonts/SFUIDisplay-Semibold.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/SFUIDisplay-Semibold.woff2") format("woff2"),
    url("assets/fonts/SFUIDisplay-Semibold.woff") format("woff"),
    url("assets/fonts/SFUIDisplay-Semibold.ttf") format("truetype"),
    url("assets/fonts/SFUIDisplay-Semibold.svg#SFUIDisplay-Semibold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF UI Display Thin";
  src: url("assets/fonts/SFUIDisplay-Thin.eot");
  src: url("assets/fonts/SFUIDisplay-Thin.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/SFUIDisplay-Thin.woff2") format("woff2"),
    url("assets/fonts/SFUIDisplay-Thin.woff") format("woff"),
    url("assets/fonts/SFUIDisplay-Thin.ttf") format("truetype"),
    url("assets/fonts/SFUIDisplay-Thin.svg#SFUIDisplay-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

//Custom CSS

.quill .ql-toolbar+.ql-container.ql-snow {
  min-height: 150px !important;
}

.fs-10 {
  font-size: $theme-size-10 !important;
}

.fs-11 {
  font-size: $theme-size-11 !important;
}

.fs-12 {
  font-size: $theme-size-12 !important;
}

.fs-13 {
  font-size: $theme-size-13 !important;
}

.fs-14 {
  font-size: $theme-size-14 !important;
}

.fs-15 {
  font-size: $theme-size-15 !important;
}


.fs-16 {
  font-size: $theme-size-16 !important;
}

.fs-18 {
  font-size: $theme-size-18 !important;
}

.fs-20 {
  font-size: $theme-size-20 !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-30 {
  font-size: $theme-size-30 !important;
}

.fs-60 {
  font-size: $theme-size-60 !important;
}


.answer-type-error {
  color: $answer-type-error !important;
}

.mw-1 {
  min-width: 1px !important;
}

.mx-w-100 {
  max-width: 100% !important;
}

.mw-150 {
  max-width: 150px !important;
}

.mw-290 {
  max-width: 290px !important;
}

.mw-385 {
  max-width: 385px !important;
}

.mw-450 {
  max-width: 450px !important;
}

.min-w-82 {
  min-width: 82px;
}

.min-w-88 {
  min-width: 88px;
}

.w-40 {
  width: 40px !important;
}

.min-h-28 {
  min-height: 28px !important;
}

.min-h-48 {
  min-height: 48px !important;
}

.h-35 {
  height: 35px !important;
}

.h-40 {
  height: 40px !important;
}

.w-64 {
  width: 64px;
}

.width-100 {
  width: 100px !important;
}

.h-64 {
  height: 64px;
}

.w-60 {
  width: 60px;
}

.h-60 {
  height: 60px;
}

.height-100 {
  height: 100px !important;
}

.height-200 {
  height: 200px !important;
}

.mw-90 {
  min-width: 90px !important;
}

.mw-120 {
  min-width: 120px !important;
}

.mw-130 {
  min-width: 130px !important;
}

.mh-auto {
  min-height: auto !important;
}

.min-height50vh {
  min-height: 50vh !important;
}

.w-120 {
  width: 120px !important;
}

.w-150 {
  width: 150px !important;
}

.w-200 {
  width: 200px !important;
}

.w-300 {
  width: 300px !important;
}

.max-h-60vh {
  max-height: 60vh !important;
}

.h-60vh {
  height: 60vh !important;
}

.max-w-400 {
  max-width: 400px !important;
}

.max-w-500 {
  max-width: 500px !important;
}

.mw-300 {
  min-width: 300px !important;
}

.grey-color {
  color: $grey !important;
}

.theme-border {
  border: 1px solid $primary;
}

.img-thumb {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

.customCheck {
  .form-check-input {
    height: 18px;
    width: 18px;
    margin-right: 8px;
    margin-top: 5px;
  }
}

.custom-text-field label+div:after {
  border-color: $primary;
}

.theme-body-bg {
  background: $theme-body-bg;
}

.heading {
  color: $heading;
}

.sub-heading {
  color: $subheading;
}

.bg-black {
  background: $black;
}

.divider {
  background: $divider;
}

.bg-purple {
  background: $bgpurple;
}

.text-purple {
  color: #8a5afc !important;
}

.profileTop {
  @include below-sm() {
    display: inline-block !important;
  }

  .profilebg {
    background: $profile-bg;
    overflow: hidden;

    @include below-sm() {
      margin-bottom: 10px;
    }
  }

  label {
    @include below-sm() {
      margin-bottom: 10px;
    }
  }

  p {
    @include below-sm() {
      margin: 0px !important;
    }
  }
}

.profileuser {
  color: $profile-user;
}

.bggreen {
  background: $bggreen !important;
}

.text-green {
  color: #099b6a !important;
}

.bgred {
  background: $bgred;
}

.text-red {
  color: #a06173 !important;
}

.bgorange {
  background: $bgorange;
}

.text-orange {
  color: #eb7543 !important;
}

.bg-theme2 {
  background: #eb7543 !important;
}


// #f48b19
.bgdarkgreen {
  background: $bgdarkgreen !important;
}

.text-theme {
  color: #4fb5b5 !important;
}

.bg-theme {
  background: $primary;
}

.bg-white-tr {
  background: rgba($white, 0.2);
}

.border-light {
  border-color: $border-light !important;
}

.labelColor {
  color: $label-color;
}

//Custom Form Right Icon
.form-right-icon {
  .form-control {
    // padding-right: 3rem;

    &.is-invalid,
    &:invalid {
      padding-right: 4.5rem;
      background-position: right 2.5rem center;
    }
  }

  .form-select {
    background-image: none;
  }
}

//Custom Tooltip Changes
.tooltip {
  position: fixed !important;

  .tooltip-inner {
    max-width: 300px;
  }

  .tooltipDottedList {
    li {
      &::before {
        content: "";
        width: 6px;
        height: 6px;
        background-color: $white;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 8px;
      }
    }
  }
}

//Sidebar Menu Backdrop showing below the 1199px screen size
.backdrop {
  display: none;

  @include below-xl() {
    display: block;
    z-index: 7;
    top: 60px;
  }
}

// Custom Scrollbar
@include above-xl() {
  * {
    scrollbar-width: thin;
    scrollbar-color: $secondary;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $secondary;
      border-radius: 20px;

      &:hover {
        background-color: $primary;
      }
    }
  }
}

@include maxheight600() {
  .pageContentInner {
    height: inherit !important;
  }
}

::-webkit-input-placeholder {
  font-size: 20px !important;
  color: red;
}

:-ms-input-placeholder {
  font-size: 20px !important;
  color: red;
}

::placeholder {
  font-size: 20px !important;
  color: red;
}

//List Style
.MuiFormControl-root {
  .label {
    &.Mui-focused {
      font-size: 1rem !important;
    }

    &.MuiFormLabel-filled {
      font-size: 1rem !important;

      &:not(.Mui-focused) {
        color: $black;
      }
    }
  }
}

.theme-card-header {
  h5 {
    padding-left: 70px;

    @include below-sm() {
      margin-bottom: 15px !important;
    }

    .theme-icon-box {
      top: -36px;
    }
  }
}

//Tabs Design
.theme-tab-header {
  .nav-tabs {
    .nav-item {
      padding-left: 4px;
      padding-right: 4px;

      &:not(:last-child) {
        margin-right: 20px;

        @include below-lg() {
          margin-right: 0;
        }
      }

      .nav-link {
        padding-top: 14px;
        padding-bottom: 0.65rem;
        min-width: 120px;

        @include below-lg() {
          min-width: inherit;
        }

        &:not(.active) {
          color: $light;
        }
      }
    }
  }

  .nav-link {
    &:not(.active) {
      color: $light !important;
      font-weight: 400 !important;
    }

    &.active {
      border-color: $dark !important;
      background: transparent !important;
    }
  }
}

.customSwitch {
  .MuiSwitch-track {
    background-color: $lightgrey !important;
    opacity: 1 !important;
  }

  .MuiSwitch-thumb {
    background: $white;
    border: 1px solid $lightgrey;
  }

  .Mui-checked {
    background: transparent;

    .MuiSwitch-thumb {
      background: $white;
      border: 1px solid $dark;
    }

    &+.MuiSwitch-track {
      background-color: $dark !important;
      opacity: 1 !important;
    }
  }
}

.MuiTablePagination-toolbar {
  color: $paging;
}

.rounded-xs {
  border-radius: $btn-border-radius-xs;
}

.eyePosition {
  position: absolute;
  right: 0;
  top: 20px;
  color: #aaaaaa;
  fill: #aaaaaa;
}

.custom-datepicker {
  min-width: 100% !important;

  fieldset {
    border: 0 !important;
    border-bottom: 1px solid #949494 !important;
    border-radius: 0 !important;
  }

  label {
    left: -6px !important;
  }

  input {
    border-radius: 8px;
    border: 1px solid #dddddd !important;
    box-shadow: none;
    font-size: 14px;
    height: 55px;
    padding-top: 8px;
    padding-left: 15px;
    margin-top: 10px;
  }

  input::placeholder {
    color: #041e42 !important;
    font-size: 1em !important;
  }
}

.datePicker {
  .MuiOutlinedInput-root {
    border-radius: 0;

    fieldset {
      border-left: 0;
      border-top: 0;
      border-right: 0;
      border-bottom: 1px solid #949494 !important;
    }

    .MuiInputBase-input {
      padding-top: 19px;
      padding-bottom: 6px;
      color: #848684;
      font-size: 15px;
      padding-left: 0;
    }

    button {
      padding-bottom: 0px;
      padding-right: 0px;

      svg {
        path {
          fill: #848684;
        }
      }
    }
  }
}

.replyActive {
  color: #408080;
}

#user-tab-container-tabpane-tab-3 .MuiDataGrid-columnHeaderTitleContainer {
  justify-content: center;
  border-bottom: none !important;
}

#user-tab-container-tabpane-tab-3 .dataGridMain .MuiDataGrid-root .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader {
  height: 23px !important;
  min-height: initial !important;
}

#user-tab-container-tabpane-tab-3 .dataGridMain .MuiDataGrid-root .MuiDataGrid-withBorderColor {
  line-height: 24px !important;
}

#user-tab-container-tabpane-tab-3 .css-2wbumw-MuiDataGrid-root .MuiDataGrid-columnSeparator--sideRight {
  right: -12px;
  display: none;
}

.level-tabs .border-light {
  border: none;
}

.assesment-qus-ans {
  padding: 0;
  margin: 0;
  list-style: none;
}

.assesment-qus-ans li {
  border-bottom: 1px solid #e1e1e1 !important;
  padding-top: 15px;
  padding-bottom: 15px;
}

.assesment-qus-text {
  font-size: 15px;
  color: #717b85;
  margin-bottom: 9px;
}

.assesment-qus-text span,
.assesment-ans-text span {
  min-width: 30px;
  margin-right: 15px;
  display: inline-block;
}

.assesment-ans-text {
  font-size: 16px;
  color: #000000de;
}

.back-btn-bg,
.back-btn-bg:hover {
  background-color: #c5d6d6;
  border-radius: 4px;
  color: #000000;
  border: none;
  min-width: 84px;
}

.set-frequency-title {
  margin-bottom: 8px;
  margin-top: 27px;
  font-size: 16px;
  color: #000000de;
}

.check-radio-btn-cover {
  label {
    font-size: 13px;
    color: $black;
    font-family: "SF UI Display Regular", Arial, sans-serif;
  }

  font-size: 19px;
}

.form-check-input {
  &:checked[type="radio"] {
    background-size: 11px;
  }
}

.weekDays input:checked+span {
  background: #408080;
  border-color: #408080;
  color: white;
}

.weekDays span {
  margin-bottom: 10px;
  border: 1px solid #dcdcdc;
  border-radius: 3px;
  color: #000000;
  cursor: pointer;
  display: inline-block;
  font-size: 14px !important;
  margin-right: 11px;
  min-width: 40px;
  padding: 4px;
  text-align: center;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(255, 193, 7, var(--bs-text-opacity)) !important;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.weekDays {
  margin-top: 28px;
}

.date-select-cover {
  margin-top: 30px;
}

.date-select-cover label {
  font-size: 13px;
  color: #717b85;
  width: 100%;
  margin-bottom: 4px;
}

.date-select-cover input {
  border: none;
  border-bottom: 1px solid #000000de;
  width: 100%;
}

.date-select-cover input:focus {
  outline: none !important;
}

.checkInner {
  align-items: center;
  display: flex;
  max-height: 80px;
  padding-top: 10px !important;
}

.checkInner .checkcontainer {
  margin: 0;
}

.checkcontainer {
  cursor: pointer;
  display: block;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 12px;
  padding-left: 25px;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  padding-top: 4px;
  color: #000000;
  font-family: "SF UI Display Regular", Arial, sans-serif;
}



.checkcontainer input {
  cursor: pointer;
}

.check-work {
  height: 16px !important;
  left: 0;
  opacity: 0 !important;
  position: absolute !important;
  top: 0;
  width: 16px !important;
}

.checkcontainer input:checked~.checkmark {
  background-color: #408080;
}

.checkmark {
  background-color: #fff;
  border-radius: 3px;
  height: 21px;
  left: 0;
  position: absolute;
  top: 0;
  width: 21px;
  border: 1px solid #b5b5b5;
}

.checkcontainer .checkmark:after {
  border: solid #fff;
  border-width: 0 0px 3px 3px;
  height: 7px;
  left: 4px;
  top: 5px;
  transform: rotate(317deg);
  width: 13px;
  display: none;
}

.checkcontainer input:checked~.checkmark:after {
  display: block;
}

.checkmark:after {
  content: "";
  position: absolute;
}

.main-check-cover {
  margin-top: 50px;
}

.form-check-input:checked {
  background-color: $primary;
  border-color: $primary;
}

.seletCover {
  width: 100%;
}

.width-400 {
  width: 300px !important;
}

.custome-heading {
  font-weight: 400;
  font-size: 14px;
  color: red !important;
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.dataGridMain {
  .MuiDataGrid-columnSeparator {
    visibility: visible !important;
    width: auto !important;
  }
}

#charCount {
  font-size: 0.7rem !important;
}

.textRedErr textarea {
  color: red !important;
}

// .textRedErr input textarea{
//     color: red !important;
// }
.clear-postion {
  position: absolute;
  margin: 10px -10px;
  cursor: pointer;
}

.MuiDataGrid-row:hover,
.MuiDataGrid-row.Mui-hovered {
  background-color: rgba(0, 0, 0, 0.04);
  border-top: 1px solid #336666 !important;
  border-bottom: 1px solid #336666 !important;
}

.MuiDataGrid-row:active,
.MuiDataGrid-row.Mui-hovered {
  background-color: rgba(0, 0, 0, 0.04);
  border-top: 1px solid #336666 !important;
  border-bottom: 1px solid #336666 !important;
}

.MuiDataGrid-row.Mui-selected {
  background-color: rgba(23, 176, 178, 0.08);
  border-top: 1px solid #336666 !important;
  border-bottom: 1px solid #336666 !important;
}

.MuiDataGrid-row:focus,
.MuiDataGrid-row.Mui-hovered {
  background-color: rgba(0, 0, 0, 0.04);
  border-top: 1px solid #336666 !important;
  border-bottom: 1px solid #336666 !important;
}

.sortDropdown {
  border: none;
  box-shadow: none;

  .p-dropdown-trigger {
    width: auto;
    margin-right: 1rem;
  }
}

.table-style3 {
  thead {
    background-color: #f7f7f7;
    border-radius: 12px;

    th {
      border: 0;
      color: var(--headings-color);
      font-family: var(--title-font-family);
      font-weight: 600;
      font-size: 15px;
      padding: 20px 30px;
    }
  }

  tbody {
    td {
      font-weight: 600;
      font-size: 15px;
      padding: 20px 30px;
    }
  }
}

.sortDropdown:hover {

  .p-dropdown-label,
  .p-dropdown-trigger {
    color: #f48b18;
  }
}

.sortDropdownpanel {
  ul {
    padding-left: 0px;
  }

  .p-dropdown-item.p-highlight {
    background-color: rgba(244, 139, 25, 0.07);
    color: var(--headings-color);
  }

  .p-dropdown-item {
    padding: 0.5rem 2.25rem 0.5rem 1.25rem;
  }
}

.page_control_shorting span {
  color: #717171;
  // margin-right: -8px;
}

.dataTable_pagination {
  .Mui-selected.MuiPaginationItem-page {
    background: #f48b19;
    color: #ffffff;
  }
}

.tabView {
  ul {
    margin-bottom: 0px;
  }
}

.pr_tabHeader {
  .p-tabview-nav-link {
    color: #717171;
  }

  .p-highlight {
    border-color: none;
  }
}

.pr_tabView {
  .p-tabview-selected>a {
    color: #181a20 !important;
  }
}


.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  background-color: #181a20;
}

.p-tabview-nav-link {
  transition: 0.3s;
  // margin-left :2px;
  // margin-right: 2px;
}

.registerTab {
  .p-tabview-nav {
    justify-content: center;
    border: none;
  }

  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    border-color: #181a20;
  }

  .p-tabview-selected {
    .p-tabview-nav-link {
      border-color: #181a20;
    }
  }

  .p-tabview-ink-bar {
    display: none;
  }
}

.p-tabview-nav-link:hover {
  border-bottom: 2px solid #181a20;
}

.checkbox-style1 {
  label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 15px;
    font-weight: 400;
    line-height: 40px !important;
    letter-spacing: 0em;
    position: relative;
    color: #4b5563;
  }

  .ant-checkbox-inner {
    border: 1px solid #041e42;
    border-radius: 4px;
    border-color: #041e42 !important;
    height: 16px;
    width: 16px;
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background: #041e42 !important;
      color: #ffffff;
    }
  }
}

textarea::placeholder {
  color: #041e42af !important;
  font-size: 1em !important;
}

[dir="rtl"] {
  .react-tel-input .form-control {
    padding-left: 20px;
  }

  .react-tel-input .flag-dropdown {
    background: none !important;
    border-radius: 0px 8px 8px 0px !important;
  }

  .eyePosition {
    left: 0;
    right: unset;
  }

  .p-datatable .p-datatable-tbody>tr>td {
    text-align: right;
  }
}

.react-tel-input .selected-flag .flag {
  position: absolute;
  top: 50%;
  left: 0;
  margin: auto;
  right: 0;
  text-align: center;
  margin-top: -5px;
}

.react-tel-input .form-control {
  border-radius: 8px !important;
  border: 1px solid #dddddd !important;
  box-shadow: none;
  width: 100% !important;
  height: 55px !important;
}

.react-tel-input .flag-dropdown {
  background: none !important;
  border-radius: 8px 0px 0px 8px !important;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background: none !important;
}

.react-tel-input .selected-flag .arrow {
  left: 0 !important;
  right: 0 !important;
  margin: auto !important;
}

.p-inputwrapper-focus {
  input {
    border: 2px solid #041e42 !important;
  }
}

.mxh-250 {
  max-height: 250px;
  max-width: 250px;
  height: 250px;
  width: 250px;
  object-fit: contain;
}

.userimg-sm {
  height: 60px;
  width: 60px;
  border-radius: 100%;
}

.ant-switch.ant-switch-checked {
  background: #f48b19 !important;
}

.ant-switch {
  background: rgba(244, 139, 25, 0.1) !important;
  height: 32px;

  // width: 50px;
  .ant-switch-handle {
    width: 25px;
    height: 25px;
    top: 4px;
  }

  .ant-switch-handle::before {
    border-radius: 50% !important;
  }

  .ant-switch-inner-checked {
    margin-top: 5px;
    // margin-inline-end: 5px !important;
  }

  .ant-switch-inner-unchecked {
    text-wrap: nowrap;
    color: #f48b19 !important;
    padding-left: 5%;
    // margin-inline-start: 5px !important;
  }
}

.ant-switch.ant-switch-checked .ant-switch-handle {
  inset-inline-start: calc(100% - 25px);
}

.radioButton {
  .ant-radio-button-wrapper-checked {
    border-color: #f48b19 !important;
    color: #f48b19 !important;
  }

  .ant-radio-button-wrapper-checked::before {
    background-color: #f48b19 !important;
  }

  .ant-radio-button-wrapper {
    color: $black;
  }
}

.packages_table {
  .p-datatable-wrapper {
    overflow: visible !important;
  }
}

.loaderCover {
  z-index: 100 !important;
}

.sort-dropdown {
  height: 55px;
  display: flex;
  align-items: center;

  .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
    min-width: 150px;
  }
}

.margin-unset {
  margin: unset !important;
}

.card-style {
  position: relative;
  max-width: 600px;
  margin: auto;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 25px 25px;

}

.box-height {
  min-height: calc(100vh - 220px);
}

.cursorPointer {
  cursor: pointer;
}

.packageStatusCss {
  padding: 10px 10px !important;
  min-width: 150px !important;
  max-width: 150px !important;
}

/* Assuming 'p-timeline' is the class applied to the PrimeReact timeline component */
// .p-timeline-event-opposite{
//   flex : 0;
// }
.timeline-icon {
  background: #06b6d4;
  color: $white;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline-card {
  padding: 10px 20px;
  border-radius: 8px;
  margin: 10px 0px;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
}

.propertyMedia {
  .ant-upload-drag {
    height: auto !important;
  }
}

.termsConditionModal {
  .ant-modal-content {
    padding: 10px 10px;
  }
}

.statistics_funfact_2 {
  background-color: #fff;
  border-radius: 16px;
  margin-bottom: 30px;
  padding: 25px 25px;
  position: relative;
  -webkit-box-shadow: 0px 1px 4px rgba(24, 26, 32, 0.07);
  -moz-box-shadow: 0px 1px 4px rgba(24, 26, 32, 0.07);
  -o-box-shadow: 0px 1px 4px rgba(24, 26, 32, 0.07);
  box-shadow: 0px 1px 4px rgba(24, 26, 32, 0.07);
}

.statistics_funfact_2 .icon {
  // background-color: #f3f5f6;
  border-radius: 50%;
  color: var(--headings-color);
  font-size: 40px;
  height: 80px;
  line-height: 85px;
  position: relative;
  width: 80px;
}

.statistics_funfact_2 .title {
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 600;
  font-size: 28px;
  line-height: 54px;
}

.antd-rangePicker {
  border: 1px solid $heading;
  border-color: $heading !important;
  height: 40px;

  .ant-picker-active-bar {
    background: $heading !important;
  }
}


.form-rangepicker {
  height: 55px;
  width: 100%;
}

.antd-rangePicker.active {
  border: 2px solid $heading;
}

// DRAG AND DROP UPLOAD
.drag-drop {
  border: 2px dashed #ccc;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  transition: border 0.3s ease-in-out;
}

.document-uploader.upload-box {
  background-color: #f9f9f9;
  padding: 20px;
}

.document-uploader.upload-box.active {
  border-color: #6dc24b;
}

.upload-info {
  margin-bottom: 20px;
}

.browse-btn {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #4a90e2;
  color: white;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}

.file-list__container {
  margin-top: 20px;
}

.file-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
}

.file-info {
  max-width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-actions {
  display: flex;
  align-items: center;
}

.success-file {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  font-size: 1.2em;
  color: #6dc24b;
}

// CUSTOM RANGE SLIDER
.range-slider-custom {
  .MuiSlider-root.MuiSlider-colorPrimary {
    color: #041527 !important;
    padding: 0px;
  }

  .MuiSlider-valueLabel {
    background: #041527;
  }
}

.active-card {
  border: 2px solid $primary;
}

.btn-setDefault {
  border: 1px solid $primary;
  color: $primary !important;
  font-weight: 500;
}

.btn-remove {
  border: 1px solid $danger;
  color: $danger !important;
  font-weight: 500;
}

// PAYMENT CARD CHECK

.card-check {
  .ant-checkbox-inner {
    border-radius: 100%;
    height: 28px;
    width: 28px;
    border: 2px solid #041527 !important;
  }

  .ant-checkbox-inner:hover {
    border-color: #041527 !important;
    background: #041527 !important;
  }

  .ant-checkbox-inner:after {
    height: 13px;
    text-align: center;
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background: #041527 !important;
      border-color: #041527 !important
    }
  }
}

.text2 {
  word-wrap: break-word;
}

// RIGHT TO LEFT DIRECTIONS
.direction-rtl {
  direction: rtl;
}

.text-right {
  text-align: right;
}

.MuiFormLabel-root.Mui-focused {
  color: $primary !important;
}

.MuiInput-underline:after {
  border-bottom-color: $primary !important;
}

.muirtl-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: $primary !important;
}

.muirtl-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: $primary !important;
}

.muirtl-1c2i806-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: $primary !important;
}

.muirtl-v4u5dn-MuiInputBase-root-MuiInput-root:after {
  border-bottom-color: $primary !important;
}